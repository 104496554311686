footer {
  margin-top: auto;
  background-color: $blue;
  color: $white;
  border-top: 1px solid $white;
  letter-spacing: 1px;
  .footer-col {
    flex-grow: 1;
    padding: 1.5rem 1rem;
    border-right: 1px solid $light-gray;
    font-size: 0.75rem;
    div > div:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    a {
      color: $white;
      &:hover,
      &:active,
      &:focus {
        color: $light-gray;
      }
    }
    &:first-child {
      border-left: 1px solid $light-gray;
    }
  }
}

/* ========================================================================== */
/* RESPONSIVE                                                                 */
/* ========================================================================== */
@include media-breakpoint-down(sm) {
  footer {
    .footer-col {
      padding: 1rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: right;
      border: none;
      font-size: 1rem;
      h4 {
        width: 40%;
        padding-right: 0.5rem;
      }
      &:not(:last-of-type) {
        border: none;
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}
