// Variables

// Color system
$black: #00000f;
$dark-gray: #222;
$light-gray: #ccc;
$white: #fff;
$blue: #000080;
$red: #d40000;

$secondary: $red;

$colors: (
  'light-gray': $light-gray,
);

$yiq-text-dark: $black;

// Options

// Spacing

// Body
$body-bg: $light-gray;
$body-color: $black;

// Links
$link-hover-decoration: none;

// Paragraphs

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  xxs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// Grid containers

// Grid columns
$grid-gutter-width: 20px;

// Components
$border-radius: 0;

// Typography
$font-family-montserrat: 'Montserrat', arial, sans-serif;
$font-family-lexend: 'Lexend', arial, sans-serif;
$font-family-sans-serif: $font-family-montserrat;
$headings-font-family: $font-family-lexend;

// Tables

// Buttons + Forms

// Buttons

// Z-index master list

// Dropdowns

// Alerts
