html {
  font-size: 16px;
  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

* {
  box-sizing: border-box;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#maincontent {
  margin-top: 5rem;
  padding: 1.5rem 0;
}

hr {
  border-color: $light-gray;
}

p {
  line-height: 1.8;
}

/* ========================================================================== */
/* FORMS, INPUTS and LABELS                                                   */
/* ========================================================================== */
form {
  .input-wrapper:not(:last-child) {
    margin-bottom: 1rem;
  }
}
input,
textarea {
  width: 100%;
  height: 2rem;
  border: 1px solid $blue;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-family: $font-family-montserrat;
}
textarea {
  height: 6rem;
  resize: vertical;
}
label {
  color: $blue;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-family: $font-family-lexend;
}

.btn {
  background-color: $blue;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;
  color: $white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1.5rem;
  font-family: $font-family-lexend;
  &:hover,
  &:active {
    border: 2px solid $blue;
  }
}

.estimate-request-form.card {
  max-width: 35rem;
  margin: auto;
}

/* ========================================================================== */
/* MISC CLASSES                                                               */
/* ========================================================================== */
.card {
  background-color: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 1.5rem;
  height: calc(100% - 1.5rem);
  width: 100%;
  &.card-content,
  .card-content {
    padding: 1rem;
    p:last-of-type {
      margin: 0;
    }
  }
  &.full-width {
    border-top: 1px solid $red;
    border-bottom: 1px solid $red;
  }
}
