.about-us {
  .bio.card {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      height: 100%;
    }
    .pic-name-wrapper {
      text-align: center;
      margin: 0 auto;
      .image-wrapper {
        background-size: cover;
        background-position: center;
        height: 16rem;
        width: 16rem;
        border-radius: 8rem;
      }
      .name {
        text-transform: uppercase;
        color: $blue;
        font-family: $font-family-lexend;
        font-size: 1.5em;
        margin: 1rem 0;
      }
    }
  }
}
