header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;

  .header-logo-wrap {
    padding: 0.5rem;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 5rem;
    }
  }

  nav {
    display: flex;
    align-items: center;
    a {
      display: inline-block;
      line-height: 3rem;
      padding: 1.5rem 1rem;
      margin: 0 2px;
      color: $black;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      font-family: $font-family-lexend;
      font-weight: 500;
      background: rgba(255, 255, 255, 0);
      will-change: background-color;
      &:hover,
      &:active {
        background: rgba($light-gray, 0.2);
      }
      &.active {
        margin: 0;
        border-right: 2px solid $blue;
        border-left: 2px solid $blue;
      }
    }
  }
}

/* ========================================================================== */
/* RESPONSIVE                                                                 */
/* ========================================================================== */

@include media-breakpoint-down(sm) {
  #maincontent {
    margin-top: 9.5rem;
  }
  header nav a {
    padding: 0.5rem 1rem;
    flex-grow: 1;
    overflow: hidden;
  }
}
